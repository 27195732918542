import React, { useEffect } from "react";

const NotFoundPage = () => {
  // on mount, redirect 404 pages to Everdays home
  useEffect(() => {
    window.location.replace("https://everdays.com/");
  }, []);
  return <div />;
};

export default NotFoundPage;
